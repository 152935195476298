<template>
    <div class="icon-container" :class="[isHtde && 'htde']">
        <template v-if="isHtde">
            <AccountIcon class="account" :is-logged-in="pageHeaderData.isLoggedIn && !pageHeaderData.isGuestBuyer" :is-reseller="pageHeaderData.isReseller" />
            <PhoneIcon class="phone" />
            <CartIcon class="cart" :cart-item-count="pageHeaderData.cartItemCount" />
        </template>
        <template v-else>
            <PhoneIcon class="visible-xs" />
            <AccountIcon class="account" :is-logged-in="pageHeaderData.isLoggedIn && !pageHeaderData.isGuestBuyer" :is-reseller="pageHeaderData.isReseller" />
            <CartIcon class="cart" :cart-item-count="pageHeaderData.cartItemCount" />
        </template>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs }                               from 'pinia';
import { computed, onBeforeMount, onMounted, watch } from 'vue';

import { ShopCode }        from '@/types/globals';
import { isCartModified }  from '@/utils/cart-utilities';
import AccountIcon         from '@/vue/components/header-actions/actions/AccountIcon.vue';
import CartIcon            from '@/vue/components/header-actions/actions/CartIcon.vue';
import PhoneIcon           from '@/vue/components/header-actions/actions/PhoneIcon.vue';
import useCartStore        from '@/vue/stores/cart-store';
import useCustomerStore    from '@/vue/stores/customer-store';

const isHtde = computed(() => window.shopShortcode === ShopCode.HTDE);

const { pageHeaderData, customer } = storeToRefs(useCustomerStore());
const { cart } = storeToRefs(useCartStore());
const { getPageHeaderData } = useCustomerStore();

watch(customer, (value, oldValue) => {
    // You might think we can just do `value !== oldValue`, but that would cause unnecessary API calls
    // if the customer data changes (without logging out or logging in).
    const loggedIn = oldValue === null && value !== null;
    const loggedOut = value === null && oldValue !== null;
    if (loggedIn || loggedOut) {
        update();
    }
});

watch(cart, (value, oldValue) => {
    if (isCartModified(value, oldValue)) {
        update();
    }
});

onMounted(async () => {
    if (isHtde.value) {
        (await import('@/lib/main-menu'));
    }
});

onBeforeMount(async () => {
    await update();
});


async function update() {
    await getPageHeaderData()
        .catch(() => {
            // Error has already been logged ─ just catch here to silence it.
        });
}
</script>
