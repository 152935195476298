<template>
    <span @click="gotoAccount()">
        <template v-if="isGermanFv && !pageHeaderData.isLoggedIn">
            {{ translate('login.form.loginButton', 'account') }} / {{ translate('register.form.registerButton.saveCart', 'account') }}
        </template>
        <template v-else>
            {{ translate(pageHeaderData.isLoggedIn ? 'my.nav.header' : 'login.form.loginButton', 'account') }}
        </template>
    </span>
</template>

<script setup lang="ts">
import { storeToRefs }                    from 'pinia';
import { computed, onBeforeMount, watch } from 'vue';
import { useRouter }                      from 'vue-router';

import { ShopCode }     from '@/types/globals';
import translate        from '@/utils/i18n';
import useCustomerStore from '@/vue/stores/customer-store';

const router = useRouter();

const isGermanFv = computed(() => [ShopCode.FVDE, ShopCode.FVAT, ShopCode.FVCH].includes(window.shopShortcode));

const { pageHeaderData, customer } = storeToRefs(useCustomerStore());
const { getPageHeaderData } = useCustomerStore();

watch(customer, (value, oldValue) => {
    // You might think we can just do `value !== oldValue`, but that would cause unnecessary API calls
    // if the customer data changes (without logging out or logging in).
    const loggedIn = oldValue === null && value !== null;
    const loggedOut = value === null && oldValue !== null;
    if (loggedIn || loggedOut) {
        update();
    }
});

onBeforeMount(async () => {
    await update();
});

async function update() {
    await getPageHeaderData()
        .catch(() => {
            // Error has already been logged ─ just catch here to silence it.
        });
}

function gotoAccount(): void {
    if (window.location.pathname.startsWith('/account')) {
        router.push({ name: 'personal-data' });
        const mobileMenuTrigger = document.getElementById('mobilemenu_toggle');
        if (mobileMenuTrigger !== null) {
            mobileMenuTrigger.click();
        }

        return;
    }

    window.location.href = '/account';
}
</script>

<style scoped>
span:hover {
    cursor: pointer;
}
</style>
