<template>
    <template v-if="isLoggedIn">
        <Popover class="popover-container"
                 :class="[isHtde && 'usermenu']"
                 id="usermenu"
                 :hover="canHover"
                 :click="false"
                 :show="canHover ? null : false"
                 placement="bottom"
                 :disabled="!isDesktop"
                 :arrow="!isHtde"
                 :openDelay="0"
                 :closeDelay="100">
            <template #default>
                <button @click="gotoPage('/account')" :title="$translate('my.nav.header', 'account')" aria-label="account">
                    <AccountIconLoggedInSvg class="header-action-icon" viewBox="0 0 32 34" />
                    <template v-if="isHtde">
                        <span class="icon-label">{{ translate('my.nav.header', 'account') }}</span>
                    </template>
                </button>
            </template>
            <template #content>
                <ul>
                    <router-link v-for="route in filteredRoutes"
                                 :key="route.name"
                                 :to="route.path"
                                 custom
                                 v-slot="{ href }: { href: string; }">
                        <li @click="gotoPage(href)">
                            <span>{{ translate(route.meta.label, 'account') }}</span>
                        </li>
                    </router-link>
                </ul>
            </template>
        </Popover>
    </template>
    <template v-else>
        <div id="usermenu" :class="[isHtde && 'usermenu']" @click="gotoPage('/account')">
            <div>
                <AccountIconSvg class="header-action-icon" viewBox="0 0 32 34" />
                <template v-if="isHtde">
                    <span class="icon-label">{{ translate('login.form.loginButton', 'account') }}</span>
                </template>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import { useMediaQuery }        from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { useRouter }            from 'vue-router';

import AccountIconSvg         from '@/images/icons/account-icon.svg';
import AccountIconLoggedInSvg from '@/images/icons/account-icon-logged-in.svg';
import { ShopCode }           from '@/types/globals';
import translate              from '@/utils/i18n';
import Popover                from '@/vue/components/UI/Popover.vue';

const props = defineProps<{
    isLoggedIn: boolean | null;
    isReseller: boolean | null;
}>();

const router    = useRouter();
const isDesktop = useMediaQuery('(min-width: 992px)');
const canHover  = useMediaQuery('(hover: hover)');

const isHtde = computed(() => window.shopShortcode === ShopCode.HTDE);

const filteredRoutes = computed(() => {
    return router.getRoutes().filter(
        value => {
            return typeof value.meta.showInMenu === 'function' ?
                value.meta.showInMenu(props.isReseller) : value.meta.showInMenu;
        },
    );
});

function gotoPage(uri: string): void {
    if (window.location.pathname.startsWith('/account')) {
        router.push(uri);
        const mobileMenuTrigger = document.getElementById('mobilemenu_toggle');
        if (!isDesktop.value && mobileMenuTrigger !== null) {
            mobileMenuTrigger.click();
        }

        return;
    }

    window.location.href = uri;
}
</script>

<style scoped lang="scss">
@import "@/global/css/redesign/scss/general/variables";
@import "@/global/scss/viewport";

.popover-container {
    @media (max-width: $viewport-sm-max) {
        border: none !important;
        margin: 0 !important;
    }

    :deep(> div:first-of-type) {
        display: flex;
        align-items: center;
    }
}

:deep(.popper) {
    z-index: 51 !important;
    background-color: $nf-color-neutral-000;
    background-clip: padding-box !important;
    padding: 5px 0 !important;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px #0003 !important;
}

ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0 $size-2;

    li {
        cursor: pointer;

        &:not(.htde) {
            font-weight: $font-regular;
            padding: 6px;
            font-size: 13px;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid $nf-color-neutral-150;
            }
        }

        &.htde {
            padding: 9px 0;
            color: $nf-color-neutral-900;
            font-size: $font-size-secondary;
            line-height: 18px;

            &:not(:last-of-type) {
                border-bottom: 1px solid $nf-color-neutral-100;
            }
        }
    }
}
</style>
