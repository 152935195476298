<template>
    <button @click="openCallLink()" aria-label="phone">
        <PhoneIcon class="header-action-icon" viewBox="0 0 32 32" />
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import PhoneIcon    from '@/images/icons/phone-icon.svg';
import { ShopCode } from '@/types/globals';

function openCallLink(): void {
    const desktopPhoneEl = document.querySelector('a.header-details-item[href^="tel"]') as HTMLAnchorElement | null;
    
    if (desktopPhoneEl) {
        window.location.href = desktopPhoneEl.getAttribute('href');
        return;
    }
    
    window.location.href = 'tel:+49711860600';
}
</script>
