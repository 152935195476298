import '@/modules/header-actions/styles.scss';

import { createApp }  from 'vue';

import HeaderActions           from '@/vue/components/header-actions/HeaderActions.vue';
import MobileMenuAccountButton from '@/vue/components/header-actions/MobileMenuAccountButton.vue';
import globals                 from '@/vue/plugins/globals';
import router                  from '@/vue/plugins/router';
import store                   from '@/vue/plugins/store';
import translator              from '@/vue/plugins/translator';
import { accountRouter }       from '@/vue/routes/account';

const headerActionsAppContainer = document.getElementById('header-actions');
if (headerActionsAppContainer !== null) {
    const headerElementApp = createApp(HeaderActions);

    headerElementApp.use(store);
    headerElementApp.use(globals);
    headerElementApp.use(router);
    headerElementApp.use(translator);
    headerElementApp.use(accountRouter);

    headerElementApp.mount(headerActionsAppContainer);
}

const headerAccountMobileActionAppContainer = document.getElementById('headerLoginMobile');
if (headerAccountMobileActionAppContainer !== null) {
    const headerAccountMobileActionApp = createApp(MobileMenuAccountButton);

    headerAccountMobileActionApp.use(store);
    headerAccountMobileActionApp.use(translator);
    headerAccountMobileActionApp.use(accountRouter);

    headerAccountMobileActionApp.mount(headerAccountMobileActionAppContainer);
}
