<template>
    <button @click="gotoCartPage()" :title="$translate('index.header.title', 'cart')" aria-label="cart">
        <CartIconSvg class="header-action-icon" viewBox="0 0 35 34" />
        <span v-if="cartItemCount !== null && cartItemCount > 0" class="icon-pill right">{{ cartItemCount }}</span>
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import CartIconSvg  from '@/images/icons/cart-icon.svg';
import { ShopCode } from '@/types/globals';

const props = defineProps<{
    cartItemCount: number | null;
}>();

function gotoCartPage(): void {
    window.location.href = '/cart';
}
</script>
